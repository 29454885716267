<template>
  <div class="grid p-fluid">
    <div class="col-12 md:col-4">
      <div class="card">
        
        <div class="card-body">
          <div class="d-flex flex-column align-items-center text-center">
            <!-- <img :src="profile_image" alt="Admin" class="rounded-circle" width="150"> -->
            <img
            style="width: 200px; border-radius: 50%; height: 200px;"
              class="rounded-circle"
              
              :src="
                data.profile_image
                  ? data.profile_image
                  : 'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o='
              "
            />

            <div class="mt-3">
              <h4>{{ data.first_name }} {{ data.last_name }}</h4>
              <p class="text-secondary mb-1">{{ data.user_type }}</p>
              <p class="text-muted font-size-sm">{{ data.address ? data.address : "-" }}</p>
              <Button label="Message" v-tooltip.bottom="'message'" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-8">
      <div class="card"> <div class="card-tittle"><b>Profile Details :- </b> </div>
        <div class="card-body">
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <h6 class="mb-0">Full Name</h6>
            </div>
            <div class="field col-12 md:col-6">
              {{ data.first_name }} {{ data.last_name }}
            </div>
          </div>
          <hr />
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <h6 class="mb-0">Mobile</h6>
            </div>
            <div class="field col-12 md:col-6">
              {{ data.mobile_number ? data.mobile_number : "-" }}
            </div>
          </div>
          <hr />
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <h6 class="mb-0">Email</h6>
            </div>
            <div class="field col-12 md:col-6">
              {{ data.email_id ? data.email_id : "-" }}
            </div>
          </div>
          <hr />
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <h6 class="mb-0">Alternate Mobile No.</h6>
            </div>
            <div class="field col-12 md:col-6">
              {{ data.alternate_contact_no ? data.alternate_contact_no : "-" }}
            </div>
          </div>
          <hr />
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <h6 class="mb-0">Gender</h6>
            </div>
            <div class="field col-12 md:col-6">
              {{ data.gender ? data.gender : "-" }}
            </div>
          </div>
          <hr />
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <h6 class="mb-0">Pan Card</h6>
            </div>
            <div class="field col-12 md:col-6">
              {{ data.pan_number ? data.pan_number : "-" }}
            </div>
          </div>
          <hr />
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <h6 class="mb-0">Adhar Card</h6>
            </div>
            <div class="field col-12 md:col-6">
              {{ data.aadhar_number ? data.aadhar_number : "-" }}
            </div>
          </div>
          <hr />
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <h6 class="mb-0">City</h6>
            </div>
            <div class="field col-12 md:col-6">
              {{ data.city ? data.city : "-" }}
            </div>
          </div>
          <hr />
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <h6 class="mb-0">Address</h6>
            </div>
            <div class="field col-12 md:col-6">
              {{ data.address ? data.address : "-" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
    };
  },
  async created() {
    this.data = await JSON.parse(localStorage.getItem("user_profile"));
  },
};
</script>

<style>
body {
  margin-top: 20px;
  color: #1a202c;
  text-align: left;
  background-color: #e2e8f0;
}
.main-body {
  padding: 15px;
}
.card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.gutters-sm > .col,
.gutters-sm > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}
.h-100 {
  height: 100% !important;
}
.shadow-none {
  box-shadow: none !important;
}
</style>
